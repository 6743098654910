<template>
  <cot-form
    @input="$emit('is-valid', $event)"
    class="d-flex gap flex-column overflow-y-auto"
    :disabled="disabled"
  >
    <div class="d-flex flex-wrap gap">
      <v-checkbox
        :label="$t('t.Active')"
        v-model="computedIsActive"
        dense
        hide-details
      />
      <v-checkbox
        :label="$t('t.PortalAccess')"
        v-model="computedIsAllowPortalAccess"
        dense
        hide-details
      />
      <cgu-status
        v-if="isInSidePanel && computedIsAllowPortalAccess"
        :accepted-at="dataCguAcceptedAt"
        :text="cguCalendarLinkText"
      />
      <v-checkbox
        v-if="!computedIsActive"
        :label="$t('t.IsAnonymous')"
        v-model="computedIsAnonymous"
        dense
        hide-details
      />
    </div>
    <div
      class="d-flex gap"
      :class="{'flex-column': !fullPageDisplay}"
    >
      <v-text-field
        :label="$t('t.Title')"
        v-model="computedTitle"
        dense
      />
      <v-text-field
        :label="$t('t.Name')"
        v-model="computedFullname"
        :rules="required"
        dense
      />
    </div>
    <div
      class="d-flex gap"
      :class="{'flex-column': !fullPageDisplay}"
    >
      <v-text-field
        type="email"
        :prepend-inner-icon="$icon('i.Email')"
        :label="$t('t.Email')"
        :rules="[...(computedIsAllowPortalAccess?required:[])]"
        v-model="computedEmail"
        dense
      />
    </div>
    <div
      class="d-flex gap"
      :class="{'flex-column': !fullPageDisplay}"
    >
      <v-select
        :items="messagingCultures"
        item-text="name"
        item-value="code"
        :label="$t('t.UserCulture')"
        v-model="computedCulture"
        dense
      />
      <v-text-field
        :label="$t('t.JobTitle')"
        v-model="computedJobTitle"
        dense
      />
    </div>
    <div
      class="d-flex gap"
      :class="{'flex-column': !fullPageDisplay}"
    >
      <v-text-field
        :label="$t('t.AvailabilityHours')"
        v-model="computedAvailabilityHours"
        dense
      />
    </div>
    <div
      class="d-flex gap"
      :class="{'flex-column': !fullPageDisplay}"
    >
      <v-text-field
        :label="$t('t.Phone')"
        v-model="computedPhone1"
        dense
      >
        <template v-slot:prepend-inner>
          <phone-btn
            :phone-number="computedPhone1"
            :show-phone-number="false"
          />
        </template>
      </v-text-field>
      <v-text-field
        :label="$t('t.Phone2')"
        v-model="computedPhone2"
        dense
      >
        <template v-slot:prepend-inner>
          <phone-btn
            :phone-number="computedPhone2"
            :show-phone-number="false"
          />
        </template>
      </v-text-field>
      <v-text-field
        :label="$t('t.Fax')"
        v-model="computedFax"
        dense
      />
    </div>
    <div
      class="d-flex gap"
      :class="{'flex-column': !fullPageDisplay}"
    >
      <v-text-field
        :label="$t('t.Address1')"
        v-model="computedAddress1"
        dense
      />
      <v-text-field
        :label="$t('t.Address2')"
        v-model="computedAddress2"
        dense
      />
      <v-text-field
        :label="$t('t.Address3')"
        v-model="computedAddress3"
        dense
      />
    </div>
    <div
      class="d-flex gap"
      :class="{'flex-column': !fullPageDisplay}"
    >
      <v-text-field
        :label="$t('t.City')"
        v-model="computedCity"
        dense
      />
      <v-text-field
        :label="$t('t.StateProvince')"
        v-model="computedStateProvince"
        dense
      />
      <v-text-field
        :prepend-inner-icon="$icon('i.MapMarker')"
        :label="$t('t.Country')"
        v-model="computedCountry"
        dense
      />
      <v-text-field
        :label="$t('t.PostalCode')"
        v-model="computedPostalCode"
        dense
      />
    </div>
    <div
      class="d-flex gap"
      :class="{'flex-column': !fullPageDisplay}"
    >
      <v-text-field
        :prepend-inner-icon="$icon('i.ImportKey')"
        :label="$t('t.ImportKey')"
        v-model="computedImportKey"
        dense
      />
      <v-checkbox
        :label="$t('t.IsImportOverwritable')"
        v-model="computedIsImportOverwritable"
        dense
      />
      <picker
        v-if="mustSelectDataSource"
        :label="$t('t.ImportDataSources')"
        :document-types="['import-data-sources']"
        hide-details
        :selected-id.sync="computedDataSourceId"
        dense
      />
    </div>
  </cot-form>
</template>

<script>
export default {
  components: {
    CguStatus: () => import('@/components/cgu-status'),
    CotForm: () => import('@/components/cot-form'),
    PhoneBtn: () => import('@/components/phone-btn'),
    Picker: () => import('@/components/picker')
  },
  computed: {
    computedAddress1: {
      get () {
        return this.dataAddress1
      },
      set (v) {
        this.dataAddress1 = v
        this.emitDocument()
      }
    },
    computedAddress2: {
      get () {
        return this.dataAddress2
      },
      set (v) {
        this.dataAddress2 = v
        this.emitDocument()
      }
    },
    computedAddress3: {
      get () {
        return this.dataAddress3
      },
      set (v) {
        this.dataAddress3 = v
        this.emitDocument()
      }
    },
    computedAvailabilityHours: {
      get () {
        return this.dataAvailabilityHours
      },
      set (v) {
        this.dataAvailabilityHours = v
        this.emitDocument()
      }
    },
    computedCity: {
      get () {
        return this.dataCity
      },
      set (v) {
        this.dataCity = v
        this.emitDocument()
      }
    },
    computedCountry: {
      get () {
        return this.dataCountry
      },
      set (v) {
        this.dataCountry = v
        this.emitDocument()
      }
    },
    computedCulture: {
      get () {
        return this.dataCulture
      },
      set (v) {
        this.dataCulture = v
        this.emitDocument()
      }
    },
    computedDataSourceId: {
      get () {
        return this.dataDataSourceId
      },
      set (v) {
        this.dataDataSourceId = v
        this.emitDocument()
      }
    },
    computedEmail: {
      get () {
        return this.dataEmail
      },
      set (v) {
        this.dataEmail = v
        this.emitDocument()
      }
    },
    computedFax: {
      get () {
        return this.dataFax
      },
      set (v) {
        this.dataFax = v
        this.emitDocument()
      }
    },
    computedImportKey: {
      get () {
        return this.dataImportKey
      },
      set (v) {
        this.dataImportKey = v
        this.emitDocument()
      }
    },
    computedIsActive: {
      get () {
        return this.dataIsActive
      },
      set (v) {
        this.dataIsActive = v
        this.emitDocument()
      }
    },
    computedIsAllowPortalAccess: {
      get () {
        return this.dataIsAllowPortalAccess
      },
      set (v) {
        this.dataIsAllowPortalAccess = v
        this.emitDocument()
      }
    },
    computedIsAnonymous: {
      get () {
        return this.dataIsAnonymous
      },
      set (v) {
        this.dataIsAnonymous = v
        this.emitDocument()
      }
    },
    computedIsImportOverwritable: {
      get () {
        return this.dataIsImportOverwritable
      },
      set (v) {
        this.dataIsImportOverwritable = v
        this.emitDocument()
      }
    },
    computedIsLocked: {
      get () {
        return this.dataIsLocked
      },
      set (v) {
        this.dataIsLocked = v
        this.emitDocument()
      }
    },
    computedFullname: {
      get () {
        return this.dataFullname
      },
      set (v) {
        this.dataFullname = v
        this.emitDocument()
      }
    },
    computedJobTitle: {
      get () {
        return this.dataJobTitle
      },
      set (v) {
        this.dataJobTitle = v
        this.emitDocument()
      }
    },
    computedPhone1: {
      get () {
        return this.dataPhone1
      },
      set (v) {
        this.dataPhone1 = v
        this.emitDocument()
      }
    },
    computedPhone2: {
      get () {
        return this.dataPhone2
      },
      set (v) {
        this.dataPhone2 = v
        this.emitDocument()
      }
    },
    computedPostalCode: {
      get () {
        return this.dataPostalCode
      },
      set (v) {
        this.dataPostalCode = v
        this.emitDocument()
      }
    },
    computedStateProvince: {
      get () {
        return this.dataStateProvince
      },
      set (v) {
        this.dataStateProvince = v
        this.emitDocument()
      }
    },
    computedTitle: {
      get () {
        return this.dataTitle
      },
      set (v) {
        this.dataTitle = v
        this.emitDocument()
      }
    }
  },
  data () {
    return {
      dataAddress1: null,
      dataAddress2: null,
      dataAddress3: null,
      dataAvailabilityHours: null,
      dataCguAcceptedAt: false,
      dataCity: null,
      dataCountry: null,
      dataCulture: null,
      dataDataSourceId: null,
      emitDocument: this.$nextTickDedup(this.innerEmitDocument),
      dataEmail: null,
      dataFax: null,
      dataFullname: null,
      dataGroupId: null,
      dataImportKey: null,
      dataIsActive: true,
      dataIsAllowPortalAccess: true,
      dataIsAnonymous: false,
      dataIsImportOverwritable: true,
      dataIsLocked: false,
      dataJobTitle: null,
      dataPhone1: null,
      dataPhone2: null,
      dataPostalCode: null,
      dataStateProvince: null,
      dataTitle: null,
      required: [v => !!v || this.$t('t.IsRequired')]
    }
  },
  inject: {
    isInSidePanel: { default () { return false } }
  },
  methods: {
    innerEmitDocument () {
      const doc = {
        address1: this.dataAddress1,
        address2: this.dataAddress2,
        address3: this.dataAddress3,
        availabilityHours: this.dataAvailabilityHours,
        cguAcceptedAt: this.dataCguAcceptedAt,
        city: this.dataCity,
        country: this.dataCountry,
        culture: this.dataCulture,
        email: this.dataEmail,
        fax: this.dataFax,
        fullname: this.dataFullname,
        groupId: this.dataGroupId,
        importDataSourceId: this.dataDataSourceId,
        importKey: this.dataImportKey,
        isActive: this.dataIsActive,
        isAllowPortalAccess: this.dataIsAllowPortalAccess,
        isAnonymous: this.dataIsAnonymous,
        isImportOverwritable: this.dataIsImportOverwritable,
        isLocked: this.dataIsLocked,
        jobTitle: this.dataJobTitle,
        phone1: this.dataPhone1,
        phone2: this.dataPhone2,
        postalCode: this.dataPostalCode,
        stateProvince: this.dataStateProvince,
        title: this.dataTitle
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataAddress1 = v?.address1
        this.dataAddress2 = v?.address2
        this.dataAddress3 = v?.address3
        this.dataAvailabilityHours = v?.availabilityHours
        this.dataCguAcceptedAt = v?.cguAcceptedAt
        this.dataCity = v?.city
        this.dataCountry = v?.country
        this.dataCulture = v?.culture
        this.dataDataSourceId = v?.importDataSourceId
        this.dataEmail = v?.email
        this.dataFax = v?.fax
        this.dataFullname = v?.fullname
        this.dataImportKey = v?.importKey
        this.dataIsActive = v?.isActive
        this.dataIsAllowPortalAccess = v?.isAllowPortalAccess
        this.dataIsAnonymous = v?.isAnonymous
        this.dataIsImportOverwritable = v?.isImportOverwritable
        this.dataIsLocked = v?.isLocked
        this.dataJobTitle = v?.jobTitle
        this.dataPhone1 = v?.phone1
        this.dataPhone2 = v?.phone2
        this.dataPostalCode = v?.postalCode
        this.dataStateProvince = v?.stateProvince
        this.dataTitle = v?.title
      }
    },
    dataIsActive (isActive) {
      if (isActive) {
        this.computedIsAnonymous = !isActive
      }
    }
  },
  props: {
    cguCalendarLinkText: String,
    disabled: {
      required: true,
      type: Boolean
    },
    documentIsNew: {
      required: true,
      type: Boolean
    },
    fullPageDisplay: {
      required: true,
      type: Boolean
    },
    messagingCultures: {
      required: true,
      type: Array
    },
    mustSelectDataSource: {
      required: true,
      type: Boolean
    },
    value: {
      required: true,
      type: Object
    }
  }
}
</script>
